import { render } from "./TestExportToExcel.vue?vue&type=template&id=80b7fd28"
import script from "./TestExportToExcel.vue?vue&type=script&lang=ts"
export * from "./TestExportToExcel.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "80b7fd28"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('80b7fd28', script)) {
    api.reload('80b7fd28', script)
  }
  
  module.hot.accept("./TestExportToExcel.vue?vue&type=template&id=80b7fd28", () => {
    api.rerender('80b7fd28', render)
  })

}

script.__file = "src/views/TestExportToExcel.vue"

export default script