<template>
  <export-to-excel
    :json_to_sheet="jsonArr"
    sheetName="jsonObjs"
    fileName="test2.xlsx"
  />
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ExportToExcel from "@/components/ExportToExcel.vue";

@Options({
  data() {
    return {
      jsonArr: [
        { a: 1, b: 2, C: 3 },
        { a: 4, b: 5, C: 6 }
      ],
      aoa: [
        ["a", "b", "c"],
        ["1", "2", "3"]
      ]
    };
  },
  components: {
    ExportToExcel
  }
})
export default class TestExportToExcel extends Vue {}
</script>
